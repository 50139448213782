<template>
  <div class="auth-wrapper auth-v2">
    <div
      class="auth-inner"
      style="background: #93278F"
    >
      <v-row
        class="auth-row ma-0"
      >
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-brand-logo pt-4">
            <div class="d-flex justify-center">
              <div>
                <v-img
                  :src="appLogoLogin"
                  max-height="100%"
                  max-width="100%"
                  alt="logo"
                  contain
                  class="justify-center"
                ></v-img>
              </div>
            </div>
          </div>
          <div class="auth-illustrator-wrapper">
            <v-img
              class="auth-mask-bg"
              :src="require(`@/assets/images/shapes/rounded.png`)"
            >
            </v-img>

            <!-- rounded -->

            <!-- 3d character -->
            <div class="d-flex align-center h-full">
              <v-img
                contain
                max-width="100%"
                height="500"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/lita-ari.png`)"
              ></v-img>
            </div>
            <!-- 3d character -->
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--black mb-2">
                    Selamat Datang
                    <br> di Aplikasi E-Smart Literasia 👋🏻
                  </p>
                  <p class="mb-2">
                    Silahkan login dengan akunmu dan mulailah belajar
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email or Username"
                      placeholder="Email or Username"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <v-checkbox
                        hide-details
                        label="Remember Me"
                        class="mt-0"
                      >
                      </v-checkbox>

                      <!-- forget link -->
                      <router-link
                        :to="{name:'auth-forgot-password'}"
                        class="ms-3"
                      >
                        Forgot Password?
                      </router-link>
                    </div>

                    <v-btn
                      :loading="isLoadingButton"
                      :disabled="isLoadingButton"
                      color="primary"
                      type="submit"
                      class="mt-6 mb-7"
                      x-large
                      block
                      width="100%"
                    >
                      Login
                    </v-btn>
                    <p class="text-center font-weight-normal text--black">
                      Dapatkan Aplikasi Literasia hanya di
                    </p>
                    <a
                      class="d-flex justify-center"
                      href="https://play.google.com/store/apps/details?id=com.literasia.literasialibrary&hl=in&gl=ID"
                    >
                      <img

                        height=""
                        src="../assets/images/logos/google-playstore.png"
                        style="width: 50%;"
                      >
                    </a>
                  </v-form>
                </v-card-text>
              </v-card>
              <v-overlay
                :value="isLoadingGoogle"
                :absolute="false"
              >
                <v-progress-circular
                  :size="100"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
                <p class="mt-5">
                  Google Sign in...
                </p>
              </v-overlay>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store/index'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline, mdiGoogle } from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, ref } from '@vue/composition-api'
import { getToken } from 'firebase/messaging'

export default {
  setup(props, context) {
    // Template Ref
    const loginForm = ref(null)
    const isLoadingButton = ref(false)
    const isLoadingGoogle = ref(false)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])

    const showSnackbar = snackbarData => {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    }

    const handlePermission = () => {
      Notification.requestPermission().then(async permission => {
        if (permission === 'granted') {
          await getToken(context.root.$messaging, {
            vapidKey: process.env.VUE_APP_SOCIAL_VAPID_KEY,
          })
            .then(currentToken => {
              if (currentToken) {
                console.log('Success get token')
                context.root.$services.FcmServices.allowNotification(currentToken).then(
                  res => {
                    localStorage.setItem('fcmToken', currentToken)
                  },
                  err => {
                    console.log('error', err)
                  },
                )
              } else {
                console.log('No registration token available. Request permission to generate one.')
              }
            })
            .catch(err => {
              console.log('An error occurred while retrieving token. ', err)
            })
        } else {
          console.log('Notification disallowed')
        }
      })
    }

    const SocialLogin = (provider, response) => {
      isLoadingGoogle.value = true
      context.root.$services.AuthServices.oauthCallback(response).then(
        resp => {
          store.dispatch('auth/googleLogin', resp.data.token).then(
            res => {
              if (localStorage.getItem('user') === 'undefined') localStorage.removeItem('user')
              handlePermission()
              isLoadingButton.value = false
              router.push('/')

              return res.data
            },
            error => {
              console.error('Oops, Unable to login!')
              console.log('error :>> ', error.response)
              errorMessages.value = error.response.data.error
              isLoadingButton.value = false
              showSnackbar({
                text: error.response.data.error ? error.response.data.error : 'Unable to login',
                color: 'error',
              })
            },
          )
        },
        err => {
          console.log(err)
        },
      )
      isLoadingGoogle.value = false
    }

    const AuthProvider = async provider => {
      isLoadingGoogle.value = true
      const bb = this
      await context.root.$auth
        .authenticate(provider)
        .then(response => {
          SocialLogin(provider, response)
        })
        .catch(err => {
          console.log('error', { err })
        })
      isLoadingGoogle.value = false
    }

    const socialLink = [
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
        provider: 'google',
      },
    ]

    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      const data = {
        username: email.value,
        password: password.value,
      }

      isLoadingButton.value = true
      await store
        .dispatch('auth/login', data)
        .then(res => {
          if (localStorage.getItem('user') === 'undefined') localStorage.removeItem('user')

          handlePermission()
          // const newAbilities = JSON.parse(localStorage.getItem('userAbility'))
          // context.root.$ability.update(newAbilities)
          router.push('/').catch(error => {
            console.info(error.message)
          })
          // window.location.href = '/'
        })
        .catch(error => {
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error)
          errorMessages.value = error.message
          isLoadingButton.value = false
        })
      isLoadingButton.value = false
    }

    return {
      handleFormSubmit,
      // userAbility,
      isPasswordVisible,
      email,
      password,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,
      appLogoLogin: themeConfig.app.logoLogin,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
      isLoadingButton,
      isLoadingGoogle,

      // social login
      AuthProvider,
      SocialLogin,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
